import FeatherIcon from 'feather-icons-react';

function Footer() {

  let year = new Date().getFullYear()

  return (

    <footer class="pt-3 mt-3 bg-light">
	    
	    <div class="card-body text-center p-1">
    		<small>Copyright &copy; {year} | Powered by <a href="https://www.tigonsports.com/">TigonSports</a></small>
    	</div>

    	<div class="phone d-sm-none d-inline">
    		<a href="tel:07971278440"><FeatherIcon icon="phone" /></a>
    	</div>

    </footer>
  );
}

export default Footer;
