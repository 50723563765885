function Buttons() {
  return (

     <div class="card-deck mt-3 mb-3">

		<div class="card border-danger">
			<img class="card-img-top" src="/images/lpkick.jpg" />
		<div class="card-body">
		    <p class="card-text"><a class="btn btn-danger btn-block" href="/little-panda" role="button">Little Pandas</a></p>
		</div></div>

		<div class="card border-danger">
			<img class="card-img-top" src="/images/IMG_5477.jpg" />
		<div class="card-body">
		    <p class="card-text"><a class="btn btn-danger btn-block" href="/taekwondo" role="button">Taekwondo</a></p>
		</div></div>

		<div class="card border-danger">
			<img class="card-img-top" src="/images/IMG_5475.jpg" />
		<div class="card-body">
		    <p class="card-text"><a class="btn btn-danger btn-block" href="/sparring-academy" role="button">Sparring Academy</a></p>
		</div></div>

		<div class="card border-danger">
			<img class="card-img-top" src="/images/IMG_2232.jpeg" />
		<div class="card-body">
		    <p class="card-text"><a class="btn btn-danger btn-block" href="https://www.tigonsports.com/" role="button">Shop</a></p>
		</div></div>

    </div>

  );
}

export default Buttons;
