import { useForm } from "react-hook-form";

import * as FormData from 'form-data';
import Mailgun from 'mailgun.js';
const mailgun = new Mailgun(FormData);

export default function BookFreeTrial() {

  const { register, handleSubmit, formState: { errors } } = useForm();

/*
  const handleChange = (e) => {
    console.log(e);
    setData({...data, [e.target.name]: e.target.value});
  };
*/

  var data = {

    'ages': [...Array(100).keys()].slice(4),
    'class': [
      'Taekwondo',
      'Little PUMAs'
    ],
    'location': [
      'Long Ashton',
      'Brentry'
    ]

  };

  const mg = mailgun.client({
    username: 'postmaster@mg.tigonsports.com', 
    key: '789b71f135688a42ce6679ff2c390f7d-8c90f339-a6e93817',
//    url: 'https://api.eu.mailgun.net'
  });

  const handleRegistration = (data) => {

    const msgData = {
      from: data.name + ' <' + data.email + '>',
      to: ['info@tigonsports.com', 'neil@saintmartialarts.co.uk'],
      subject: 'Form submitted: Book a free trail',
      text: JSON.stringify(data, null, 2)
    };

    mg.messages.create('mg.tigonsports.com', msgData)
    .then(msg => alert('Message sent successfully.')) // logs response data
    .catch(err => console.error(err)); // logs any error

    return false

  }

  const toOptions = (arr) => {

    let options = [<option></option>];
    arr.forEach(i => {
      options.push(<option value={ i }>{ i }</option>);
    });

    return options;

  }

  data.ages = toOptions(data.ages);
  data.class = toOptions(data.class);
  data.location = toOptions(data.location);

  return (
    <div>

      <form onSubmit={handleSubmit(handleRegistration)} method="post">

        <div class="form-group row">
          <label for="name" class="col-sm-2 col-form-label">Name</label>
          <div class="col-sm-10">
            <input 
              type="text" 
              class="form-control" 
              name="name" 
              {...register('name')}
              required 
              />
          </div>
        </div>

        <div class="form-group row">
          <label for="age" class="col-sm-2 col-form-label">Age</label>
          <div class="col-sm-10">
            <select class="form-control" required {...register('age')}>
            { data.ages }
            </select>
          </div>
        </div>

        <div class="form-group row">
          <label for="email" class="col-sm-2 col-form-label">Email</label>
          <div class="col-sm-10">
            <input 
              type="email"
              class="form-control"
              name="email"
              {...register('email')}
              />
          </div>
        </div>

        <div class="form-group row">
          <label for="telephone" class="col-sm-2 col-form-label">Telephone</label>
          <div class="col-sm-10">
            <input
              type="text"
              class="form-control"
              name="telephone"
              {...register('telephone')}
              required
              />
          </div>
        </div>

        <div class="form-group row">
          <label for="class" class="col-sm-2 col-form-label">Class</label>
          <div class="col-sm-10">
            <select class="form-control" required {...register('class')}>
              { data.class }
            </select>
          </div>
        </div>

        <div class="form-group row">
          <label for="location" class="col-sm-2 col-form-label">Location</label>
          <div class="col-sm-10">
            <select class="form-control" required {...register('location')}>
            { data.location }
            </select>
          </div>
        </div>

        <div class="form-group row">
          <label class="col-sm-2 col-form-label"></label>
          <div class="col-sm-10">
            <button type="submit" class="btn btn-primary mb-2 col-12 col-sm-4 float-right">Book free Trial</button>
          </div>
        </div>

      </form>

    </div>
  );
}
