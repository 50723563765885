function Selfdefence() {
  return (
    <div>

      <h1>Sparring Academy</h1>

        <div class="float-right col-md-5">
            <img src="/images/sma4.jpg" class="img-fluid" />
        </div>

        <p>Hybrid sparring academy is for those students who wish to improve their sparring in order to compete at an elite level both nationally and internationally in the future.</p>
        <p>Hybrid sparring academy is a chance to train with students across all different age groups and different abilities from a variety of martial art disciplines.</p>
        <p>With all coaches having represented their national teams whether its competing or coaching in both kickboxing and taekwondo there is a wealth of knowledge in sparring that is at your disposal. But not only that, many of your training partners are international and national champions in their own martial art.</p>
        <p>The two hour session will be split into separate parts focusing on the many aspects of sparring it starts with a ‘gas tank’ fitness session to warmup up followed by in-depth sparring drills finishing of with some sparring and mental training working on tactics and drive.</p>
         
        <p><strong>Gas Tank</strong> training is designed to push your fitness levels to the best they can be so you are prepared to step onto the mats with your body in peak physical condition so you can concentrate on the fight instead of worrying about getting tired.</p>
        <p><strong>Sparring drills</strong> part of the session is an in-depth look at your techniques making sure everything is perfect so you can be confident that you are the best when you step up against your opponent.</p>
        <p><strong>Sparring</strong> is a chance to test your skills against some of the best we have to offer with sparring partners from all ages and with many different skills there will always be someone to test and help you improve.</p>
        <p><strong>Mental preparation</strong> is where we look at the tactics of a fight so you are prepared to come up against any type of competitor and know what exactly you need to do to get the best result you can. We will also help improve your drive and determination so when you are in an competition and you may have a hard fight you will not give up and will push yourself to your limits.</p>

    </div>
  );
}

export default Selfdefence;
