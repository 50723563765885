function Home() {
  return (
    <div>

      <div class="float-right col-md-5">
        <img src="/images/sma1.jpg" class="img-fluid" />
      </div>

      <p>Welcome to the home page of the Brentry and Long Ashton schools of Taekwon-Do based in Bristol.</p>
      <p>Some of the best and friendliest martial arts training you'll find anywhere.</p>
      <p>If you are looking for somewhere for you or your family to train, why not contact us to arrange a free trial lesson.</p>
      <p>Beginners are always welcome.  Family training discounts are available.</p>
       
      Have a browse through our website to see all about the classes we run for
      <ul>
      <li>Little Pandas (aged 4-7)</li>
      <li>Juniors (aged 6-12)</li>
      <li>Adults (13+)</li>
      </ul>
      
      <p>You'll see why training with us is exactly what you are looking for.</p>
      <p><a class="btn btn-danger" href="/book-free-trial" role="button">Book Free Trial</a></p>
    </div>
  );
}

export default Home;
