function LittlePuma() {
  return (
    <div>

      <h1>Little Pandas</h1>

      <div class="float-right col-md-5">
        <img src="/images/lps.jpg" class="img-fluid" />
      </div>

      <p>This is the first Taekwon-Do class open to children from ages 4 to 7 years old.</p>

      <p>Little Pandas is a revolutionary new way of teaching 4 to 7 year olds using a martial arts platform.</p>
      <p>We use the aspects of Discipline, Courtesy and Respect that form the basis of the martial arts as the foundation of the Little Pandas syllabus.</p>

      <p>Therefore, from the very first lesson the children learn the important fundamentals that will lead to the eventual development of those all important "Life & Social Skills" that all children need.</p>
       
      The martial arts approach helps to develop skills like:
      <ul>
        <li>concentration</li>
        <li>politeness</li>
        <li>self-confidence</li>
        <li>teamwork</li>
        <li>self-control</li>
        <li>good manners</li>
      </ul>
       
      <p>Little Pandas take regular gradings so they can enjoy their progress and there are also many achievement and attendance badges.</p>
      <p>All classes are a mixture of martial arts and games. Little Pandas train for 30 minutes once per week. Parents need to watch the classes and follow the progress of their child.</p>
      <p><a class="btn btn-danger" href="/book-free-trial" role="button">Book Free Trial</a></p>

    </div>
  );
}

export default LittlePuma;
