import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min.js';
import './App.css';

import { BrowserRouter, Route, Switch } from 'react-router-dom'
import {Helmet} from "react-helmet";

import Header from './components/Header';
import Footer from './components/Footer';
import Logo from './components/Logo';
import Buttons from './components/Buttons';

import Home from './pages/Home';
import Taekwondo from './pages/Taekwondo';
import LittlePanda from './pages/LittlePanda';
import Locations from './pages/Locations';
import ContactUs from './pages/ContactUs';
import BookFreeTrial from './pages/BookFreeTrial';
import SparringAcademy from './pages/SparringAcademy';

function App() {
  return (
    <BrowserRouter>
    <div>

      <Header />
      <div class="container mt-3 mb-3">

        <Route exact path="/" component={Logo} />

        <div class="card"><div class="card-body">

          <Switch>
            <Route exact path="/" component={Home} />
            <Route exact path="/taekwondo" component={Taekwondo} />
            <Route exact path="/little-panda" component={LittlePanda} />
            <Route exact path="/sparring-academy" component={SparringAcademy} />
            <Route exact path="/locations" component={Locations} />
            <Route exact path="/contactus" component={ContactUs} />
            <Route exact path="/book-free-trial" component={BookFreeTrial} />
          </Switch>

        </div></div>

        <Route exact path="/" component={Buttons} />

      </div>
      <Footer />

    </div>
    </BrowserRouter>
  );
}

export default App;
