import FeatherIcon from 'feather-icons-react';

function Header() {
  return (
    <nav class="navbar navbar-expand-lg navbar-light bg-light">

      <a class="navbar-brand" href="/">
        <img src="/images/logo.png" height="30" alt="" />
      </a>


      <div class="collapse navbar-collapse" id="navbarSupportedContent">
        <ul class="navbar-nav mr-auto">
          <li class="nav-item">
            <a class="nav-link" href="/">Home</a>
          </li>

          <li class="nav-item dropdown">
            <a class="nav-link dropdown-toggle" href="#" id="navbarDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
              Classes
            </a>
            <div class="dropdown-menu" aria-labelledby="navbarDropdown">
              <a class="dropdown-item" href="/little-panda">Little Pandas</a>
              <a class="dropdown-item" href="/taekwondo">Taekwon-Do</a>
              <a class="dropdown-item" href="/sparring-academy">Sparring Academy</a>
            </div>
          </li>

          <li class="nav-item">
            <a class="nav-link" href="/locations">Locations</a>
          </li>
          <li class="nav-item">
            <a class="nav-link" href="/book-free-trial">Book Free Trial</a>
          </li>
          <li class="nav-item">
            <a class="nav-link" href="/contactus">Contact Us</a>
          </li>
        </ul>

      </div>

        <div class="navbar-text">
          <a href="#"><FeatherIcon icon="facebook" /></a>&nbsp;
          <a href="https://www.instagram.com/saintmartialarts/"><FeatherIcon icon="instagram" /></a>&nbsp;
          <a href="tel:07971278440" class="d-sm-none d-inline"><FeatherIcon icon="phone" /></a>&nbsp;
        </div>

      <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
        <span class="navbar-toggler-icon"></span>
      </button>

    </nav>
  );
}

export default Header;
