function Taekwondo() {
  return (
    <div>

      <h1>Taekwon-Do</h1>

        <div class="float-right col-md-5">
          <img src="/images/taekwon-do-belt.png" class="img-fluid" />
        </div>

        <p>Taekwon-Do as we know it today, was inaugurated in Korea in 1955 by Major General Choi, Hong Hi. Before that there were many different martial arts styles being taught in Korea. General Choi brought these various styles together and combined them into Taekwon-Do as we know it today.</p>
        <p>Tae means to jump, kick or smash with foot. Kwon means to punch or destroy with hand or fist. Do means art, way or method. Taekwon-Do therefore means the art of punching & kicking.</p>
        <p>There are many aspects to Taekwon-Do, with something to benefit everyone. Taekwon-Do training offers improvements in strength, endurance, flexibility, stamina, self-control, confidence and relaxation. All Taekwon-Do classes begin with a full warm-up and end with a cool down and stretch. This is important for your health and your ability to train safely and effectively.</p>
        <p>There are many aspects to the syllabus. These include learning to punch, kick & move with grace, agility & power. There is a traditional element & self defence is taught. There is a competition element for those who want it.</p>
        <p>There is not enough space here to fully describe everything. The best way to find out is to contact us, let us know what you are looking for, and we will tell you how we think we can help.</p>
        <p>Taekwon-Do is a broad discipline - whether you are 6, 14, 40 or 70, there is something for you in Taekwon-Do.</p>
        <p>Take that first step.......</p>
        <p><a class="btn btn-danger" href="/book-free-trial" role="button">Book Free Trial</a></p>

    </div>
  );
}

export default Taekwondo;
