import FeatherIcon from 'feather-icons-react';

function ContactUs() {
  return (
    <div>

      <h1>Contact Us</h1>

      <p>For more information regarding anything you have read on this website, please contact us via email and one of our instructors will be in touch as soon as possible. Alternatively, you can use the phone numbers provided to talk to us.</p>
       
      <p>
       <FeatherIcon icon="mail" /> neil@saintmartialarts.co.uk <br/>
       <FeatherIcon icon="phone" /> 07971 278440
      </p>

    </div>
  );
}

export default ContactUs;
