function Logo() {
  return (

  	<div>

    <div class="card mb-3 d-md-block d-none logo">
      <div class="card-body">
	    <div class="text-center">
	      <img src="/images/logo-black.png" class="img-fluid" />
	    </div>
      </div>
    </div>

    <div class="card mb-3">
		<div class="card-body">
		<h2>Our Focus</h2>
		<p>To create and nurture an affinity for fitness and martial arts for all abilities. Our goal is to create a love of physical activity through a supportive community enhancing physical and mental wellbeing.</p>
		</div>
    </div>

    </div>


  );
}

export default Logo;
