function Locations() {
  return (
    <div>

      <h1>Locations</h1>

      <div class="card-deck">

      <div class="card">
      <div class="card-header"><h5>Long Ashton</h5></div>
      <div class="card-body">
      <p>
        Monday and Wednesday<br/>
        Little Pandas - 5pm<br/>
        Taekwondo- 530pm
      </p>
      <p>
        Long Ashton Sports & Community Centre<br/>
        Keedwell Hill<br/>
        Long Ashton<br/>BS41 9DP
      </p>
      </div></div>

      <div class="card">
      <div class="card-header"><h5>Brentry Taekwon-Do</h5></div>
      <div class="card-body">
      <p>
        Tuesday and Thursday<br/>
        Little Pandas- 5:25pm<br/>
        Taekwondo - 6pm
      </p>
      <p>
        Brentry Primary School<br/>
        Brentry Lane<br/>
        BS10 6RG
      </p>
      </div></div>

      <div class="card">
      <div class="card-header"><h5>Sparring Academy</h5></div>
      <div class="card-body">
      <p>
        Fridays - 7pm to 7:30pm<br/>
        Martial Arts & Fitness<br/>
        Unit C Bradley Pavillions<br/>
        Pear Tree Rd<br/>
        Bristol<br/>
        BS32 0BQ
      </p>
      </div></div>

      </div>

    </div>
  );
}

export default Locations;
